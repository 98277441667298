import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Projects from "../components/projects"
import Posts from "../components/posts"
import Talks from "../components/talks"

import location from "../content/images/location.png"
import work from "../content/images/work.png"

const IndexPage = () => {
  return (<Layout>
    <SEO title="Home"/>
    <div>
      <h1>Raluca Nicola</h1>
      <p className="intro large-font"> Web cartographer with an interest in 3D and data visualization.
      <img src={work} alt="icon of woman working" className="icon"></img>
      I work as a Product Engineer with the ArcGIS API for JavaScript team at Esri.
      <img src={location} alt = "icon of woman next to a map pin" className="icon"></img>
      I'm currently based in Zurich Area, Switzerland.
      </p>
      <p id="work" className="section-title section-border">WORK</p>
      <Projects></Projects>
      <p id="blog" className="section-title section-border">BLOG</p>
      <Posts></Posts>
      <p style={{ paddingTop: '1em' }}>
        Read more of my blog posts on the <a href="https://www.esri.com/arcgis-blog/author/raluca_zurich/"  target="_blank" rel="noreferrer">Esri ArcGIS Blog</a>.
      </p>
      <p id="talks" className="section-title section-border">TALKS</p>
      <Talks></Talks>
    </div>
  </Layout>)
}

export default IndexPage
