import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./talks.css"

const Projects = () => {
  return (
    <StaticQuery query={graphql`
    query {
      talks: talksJson {
        title
        content {
          title
          date
          event {
            title
            url
          }
          url
        }
      }
    }
  `}
  render={data => {
    const content = data.talks.content;
    return (
      <ul className="talks">
        {content.map((talk, index) => {
          return <li key={index} className="item-description small-font">
            <span className="item-title">{talk.title}</span>
            <span> - <a href={talk.event.url}>{talk.event.title}</a>,</span>
            <span className="italic"> {talk.date}. { talk.url ? <a href={talk.url}>video</a> : ""}</span>
          </li>
        })}
      </ul>
      )
    }
  }
/>)
}

export default Projects

