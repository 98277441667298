import React from "react"
import ProjectCard from "./project-card"
import { StaticQuery, graphql } from "gatsby"
import "./projects.css"

const Projects = () => {
  return (
    <StaticQuery query={graphql`
    query {
      projects: allContentJson {
        edges {
          node {
            title
            content {
              description
              title
              date
              live
              github
              span {
                row
                column
              }
              image {
                src {
                  childImageSharp {
                    fluid(maxHeight: 700) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `}
  render={data => {
    const content = data.projects.edges[0].node.content;
    return (
      <div className="projects">
        {content.map((project, index) => {
          return <ProjectCard key={index} item={project}></ProjectCard>
        })}
      </div>)
    }
  }
/>)
}

export default Projects

