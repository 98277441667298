import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "./posts.css"

const Posts = () => {
  return (
    <StaticQuery query={graphql`
    query {
      posts: allMdx(sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            frontmatter {
              date
              displayDate
              slug
              title
              summary
            }
          }
        }
      }
    }
  `}
  render={data => {
    const content = data.posts.edges;
    return (
      <ul className="posts">
        {content.map((post, index) => {
          const fm = post.node.frontmatter;
          return <li key={index} className="item-description small-font">
            <Link to={fm.slug}>
              <span className="item-title">{fm.title}</span>
            </Link>

            <span className="italic"> {fm.displayDate}</span>
          </li>
        })}
      </ul>
    )
  }
  }
/>)
}

export default Posts