import React from "react"
import Img from "gatsby-image"
import "./projects.css"

const ProjectCard = ({item}) => (
    <div className="project-container" style={{
      gridColumn: `span ${item.span.column}`,
      gridRow: `span ${item.span.row}`,
    }}>
      <div className="img-hover-zoom">
      <a href={item.live} target="_blank" rel="noreferrer" aria-label={item.title}>
        <Img fluid={item.image.src.childImageSharp.fluid}/>
      </a>
      </div>
      <div className="small-font item-description">
        <span className="item-title">
          <a href={item.live} target="_blank" rel="noreferrer">
          {item.title}
          </a>
        </span>
        <span> - {item.description}</span>
        <p className="italic"> {item.date}, <a href={item.github}>Github</a></p>
      </div>
    </div>
)


export default ProjectCard
